import { GtagConsents } from 'app/models/gtag.model';
import { CookieConsent, CookieConsentItem } from '../models/consent-data.model';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    gtag: any;
    dataLayer: object[] | undefined;
  }
}

export class GtagConsentService {
  private static instance: GtagConsentService;
  private isAnotherGDPRConsentUpdate = false;

  static getInstance(): GtagConsentService {
    if (GtagConsentService.instance === undefined) {
      GtagConsentService.instance = new GtagConsentService();
    }

    return GtagConsentService.instance;
  }

  prepareForIntegration(cookieConsentItems: CookieConsentItem[]): void {
    window.dataLayer = window.dataLayer || [];
    window.gtag = function () {
      // eslint-disable-next-line prefer-rest-params
      (window.dataLayer as object[]).push(arguments);
    };

    let gtmData = this.convertCookieConsentsToGtagConsents(cookieConsentItems);
    gtmData = this.markAllGtagConsentsAsDenied(gtmData);

    window.gtag('consent', 'default', { ...gtmData, wait_for_update: 500 });
  }

  runGtagConsentMode(cookieConsentData: CookieConsent): void {
    if (!this.isGtagExist()) {
      return;
    }

    if (this.isGtagAccepted(cookieConsentData.items)) {
      this.updateConsentsInGtag(cookieConsentData.items);
    } else {
      this.stopAllConsentsInGtag(cookieConsentData.items);
    }
  }

  isGtagExist(): boolean {
    if (window.gtag === undefined) {
      return false;
    }

    return true;
  }

  isGtagAccepted(cookieConsentItems: CookieConsentItem[]): boolean {
    if (cookieConsentItems.length > 0) {
      const gtagItem = cookieConsentItems.find((item) => item.tag === 'gtag' || item.id === 'gtag');

      return Boolean(gtagItem?.accept);
    }

    return false;
  }

  convertCookieConsentsToGtagConsents(cookieConsentItems: CookieConsentItem[]): GtagConsents {
    const gtagConsents: GtagConsents = {};

    cookieConsentItems.forEach((item) => {
      const consentId = item.tag !== undefined ? item.tag : item.id;
      gtagConsents[consentId] = item.accept ? 'granted' : 'denied';
    });

    return gtagConsents;
  }

  makeGtagConsentsUpdate(gtagConsents: GtagConsents): void {
    if (!this.isGtagExist()) {
      throw new Error('Cookie Info Library: Gtag is unreachable');
    }

    /** temporary disable, it makes window refresh after again consents changed */
    // if (this.isAnotherGDPRConsentUpdate) {
    //   this.refreshBrowser();
    // }

    window.dataLayer = window.dataLayer || [];
    window.gtag('consent', 'update', gtagConsents);
    window.dataLayer.push({ event: 'consent_change' });
    this.isAnotherGDPRConsentUpdate = true;
  }

  markAllGtagConsentsAsDenied(originalData: GtagConsents): GtagConsents {
    const copyData: GtagConsents = { ...originalData };
    Object.keys(copyData).forEach((key) => (copyData[key] = 'denied'));

    return copyData;
  }

  private updateConsentsInGtag(consentItems: CookieConsentItem[]): void {
    const gtagConsents: GtagConsents = this.convertCookieConsentsToGtagConsents(consentItems);
    this.makeGtagConsentsUpdate(gtagConsents);
  }

  private refreshBrowser(): void {
    location.reload();
  }

  private stopAllConsentsInGtag(consentItems: CookieConsentItem[]): void {
    const allGtagConsents = this.convertCookieConsentsToGtagConsents(consentItems);
    const allGtagDeniedItems = this.markAllGtagConsentsAsDenied(allGtagConsents);

    this.makeGtagConsentsUpdate(allGtagDeniedItems);

    return;
  }
}
