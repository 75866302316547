export enum ComponentType {
  Static = 'static',
  Dynamic = 'dynamic',
}

export enum ComponentHostViewTag {
  Div = 'div',
  Li = 'li',
  P = 'p',
}

export interface ComponentParamsConfig {
  componentName: string;
  componentType?: ComponentType;
  componentHostViewTag?: ComponentHostViewTag;
  componentClass?: string;
}
