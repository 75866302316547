import { html, TemplateResult } from 'lit-html';
import { Subject } from 'rxjs';

import { BaseTemplate } from 'app/core/base-template';
import { TemplateRef } from 'app/core/models/template.model';
import { ConsentMenuEvents } from './consent-menu.model';

export class ConsentMenuTemplate extends BaseTemplate implements TemplateRef {
  private toggleMenu$: Subject<Date>;

  constructor() {
    super();
    this.registerEvent(ConsentMenuEvents.ToggleMenu, (this.toggleMenu$ = new Subject()));
  }

  getTemplate(): TemplateResult {
    return this.getConsentMenuTemplate();
  }

  private getConsentMenuTemplate(): TemplateResult {
    return html`
      <div @click="${this.emitToggleMenuEvent.bind(this)}" class="consent-modal__menu-btn">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <ul data-content="menu-group" class="consent-modal__menu-holder"></ul>
    `;
  }

  private emitToggleMenuEvent(): void {
    this.toggleMenu$.next(new Date());
  }
}
