import { CookieConsentDetailsType } from './consent-data.model';

export enum SupportedLanguages {
  Pl = 'pl',
  En = 'en',
  De = 'de',
}

export enum AppLabels {
  AcceptAllBtnLabel = 'ACCEPT_ALL_BTN_LABEL',
  RejectAllBtnLabel = 'REJECT_ALL_BTN_LABEL',
  AltAgreeBtnLabel = 'ALT_AGREE_BTN_LABEL',
  AltGoogleMapsAgreement = 'ALT_GOOGLE_MAPS_AGREEMENT',
  AltLearnMoreBtnLabel = 'ALT_LEARN_MORE_BTN_LABEL',
  AltLinkedInAgreement = 'ALT_LINKEDIN_AGREEMENT',
  AltLoadBtnLabel = 'ALT_LOAD_BTN_LABEL',
  AltResourceLoadingMessage = 'ALT_RESOURCE_LOADING_MESSAGE',
  AltVideoLoadingMessage = 'ALT_VIDEO_LOADING_MESSAGE',
  AltVimeoAgreement = 'ALT_VIMEO_AGREEMENT',
  AltYoutubeAgreement = 'ALT_YOUTUBE_AGREEMENT',
  BackBtnLabel = 'BACK_BTN_LABEL',
  DetailHistoryTitle = 'DETAIL_HISTORY_TITLE',
  GeneralTitle = 'GENERAL_TITLE',
  HistoryItemAccepted = 'HISTORY_ITEM_ACCEPTED',
  HistoryItemRefused = 'HISTORY_ITEM_REFUSED',
  ManageOptsBtnLabel = 'MANAGE_OPTS_BTN_LABEL',
  SaveConsentBtnLabel = 'SAVE_CONSENTS_BTN_LABEL',
}

export type AppTranslationConfig = {
  [key in AppLabels | CookieConsentDetailsType]: {
    [key in SupportedLanguages]: string;
  };
};
