import { v4 as uuid } from 'uuid';

import { User } from '../models/user.model';

export class UserService {
  private static instance: UserService;

  private user!: User;

  static getInstance(): UserService {
    if (UserService.instance === undefined) {
      UserService.instance = new UserService();
    }

    return UserService.instance;
  }

  getUser(): User {
    if (this.user === undefined) {
      this.createUser();
    }

    return this.user;
  }

  /**
   * Public API method: set user data
   */
  updateUserData(user: User): void {
    if (user !== undefined) {
      this.user = user;
    }
  }

  /**
   * Public API method: create new user data
   */
  private createUser(): void {
    this.user = {
      uuid: uuid(),
    };
  }
}
