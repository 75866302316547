import { ConsentButtonsTheme } from 'app/models/consent-wrapper.model';

export const getButtonClassNameByUsingTheme = (theme: ConsentButtonsTheme): string => {
  switch (theme) {
    case ConsentButtonsTheme.SameFilled:
      return 'c-btn';

    case ConsentButtonsTheme.Highlighted:
      return 'c-btn c-btn--text';
  }
};
