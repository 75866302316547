import { User } from './user.model';

export interface ApiCookieConsent {
  category: { [key: string]: ApiCookieConsentCategory };
  item: { [key: string]: CookieConsentItem };
  private_policy: string;
  details_description: string;
}

export interface ApiCookieConsentCategory {
  description: string;
  name: CookieConsentCategoryName;
  position: number;
}

export enum CookieConsentCategoryName {
  Strict = 'strict',
}

export interface CookieConsentCategory {
  id: string;
  description: string;
  name: CookieConsentCategoryName;
  items?: CookieConsentItem[];
  position: number;
}

export interface CookieConsentItemHistory {
  date: Date;
  approval: ConsentStorageDataApproval;
}

export interface CookieConsentItem {
  attributes: string;
  category: string;
  categoryEntity: CookieConsentCategory;
  collected: string;
  company: string;
  data_purposes: string;
  description: string;
  distribution: string;
  duration: string;
  further_information: string;
  id: string;
  legal_basis: string;
  location_of_processing: string;
  name: string;
  protection_officer: string;
  recipients: string;
  required: boolean;
  technologies: string;
  active: boolean;
  accept: boolean;
  history: CookieConsentItemHistory[];
  tag: string;
}

export enum CookieConsentDetailsType {
  Attributes = 'attributes',
  Collected = 'collected',
  Company = 'company',
  DataPurposes = 'data_purposes',
  Distribution = 'distribution',
  Duration = 'duration',
  FurtherInformation = 'further_information',
  LegalBasis = 'legal_basis',
  LocationOfProcessing = 'location_of_processing',
  Name = 'name',
  ProtectionOfficer = 'protection_officer',
  Recipients = 'recipients',
  Technologies = 'technologies',
}

export interface CookieConsent {
  category: CookieConsentCategory[];
  items: CookieConsentItem[];
  private_policy: string;
  details_description: string;
}

export interface CookieConsentHistory {
  date: Date;
  id?: string;
  ip?: string;
  settings: ConsentStorageDataApproval[];
  url: string;
  uuid?: string;
}

export interface CookieConsentHistoryResponse {
  code: number;
  data: CookieConsentHistory[];
}

export interface ConsentStorageDataApproval {
  id: string;
  value: boolean;
  item?: string;
}

export interface ConsentStorageData {
  user: User;
  approval: ConsentStorageDataApproval[];
  history: CookieConsentHistory[];
}
