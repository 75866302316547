import { html, TemplateResult } from 'lit-html';
import { Subject } from 'rxjs';

import { BaseTemplate } from 'app/core/base-template';
import { TemplateRef } from 'app/core/models/template.model';
import { AppLabels } from 'app/models/app-translations.model';
import { ConsentAltBoxEvents, ConsentBoxAltType } from './consent-alt-box.model';

export class ConsentAltBoxTemplate extends BaseTemplate implements TemplateRef {
  private learnMoreClick$: Subject<Date>;
  private runClick$: Subject<ConsentBoxAltType>;
  private currentType!: ConsentBoxAltType;

  constructor() {
    super();
    this.registerEvent(ConsentAltBoxEvents.LearnMoreClick, (this.learnMoreClick$ = new Subject()));
    this.registerEvent(ConsentAltBoxEvents.RunClick, (this.runClick$ = new Subject()));
  }

  getTemplate(consentType: ConsentBoxAltType, loadingState = false): TemplateResult {
    this.currentType = consentType;

    if (!loadingState) {
      return html`
        <span class="cookie-alt__header">
          ${this.currentType === ConsentBoxAltType.Youtube ? this.translate(AppLabels.AltYoutubeAgreement) : ''}
          ${this.currentType === ConsentBoxAltType.Vimeo ? this.translate(AppLabels.AltVimeoAgreement) : ''}
          ${this.currentType === ConsentBoxAltType.LinkedIn ? this.translate(AppLabels.AltLinkedInAgreement) : ''}
          ${this.currentType === ConsentBoxAltType.GoogleMaps ? this.translate(AppLabels.AltGoogleMapsAgreement) : ''}
        </span>
        <button
          class="c-btn c-btn--text-emerald cookie-alt__learn-more"
          @click="${this.emitLearnMoreClickEvent.bind(this)}"
        >
          ${this.translate(AppLabels.AltLearnMoreBtnLabel)}
        </button>

        <button class="c-btn c-btn--icon-right cookie-alt__run-btn" @click="${this.emitRunClickEvent.bind(this)}">
          ${this.translate(this.isVideoType() ? AppLabels.AltLoadBtnLabel : AppLabels.AltAgreeBtnLabel)}
          <i
            class="${this.isVideoType()
              ? 'interface-icon interface-icon-interface_video'
              : 'ca-icon ca-icon-permission'}"
          ></i>
        </button>
      `;
    } else {
      return html`
        <p class="cookie-alt__message">
          ${this.translate(this.isVideoType() ? AppLabels.AltVideoLoadingMessage : AppLabels.AltResourceLoadingMessage)}
        </p>
      `;
    }
  }

  getAgreementTemplate(): TemplateResult {
    return html`
      <div>
        <label class="form__label form__label--choice">
          Always unblock Youtube
          <input class="form__control form__control--check" type="checkbox" name="" id="" />
          <span class="form__checkmark form__checkmark--check"></span>
        </label>
      </div>
    `;
  }

  private emitLearnMoreClickEvent(): void {
    this.learnMoreClick$.next(new Date());
  }

  private isVideoType(): boolean {
    return [ConsentBoxAltType.Youtube, ConsentBoxAltType.Vimeo].includes(this.currentType);
  }

  private emitRunClickEvent(): void {
    this.runClick$.next(this.currentType);
  }
}
