import { filter } from 'rxjs';

import { Component } from 'app/core/base-component';
import { ApiCookieConsent, CookieConsent } from 'app/models/consent-data.model';
import { ConsentDataService } from 'app/services/consent-data.service';
import { ComponentType } from 'app/core/models/component.model';
import { ConsentModalService } from 'app/services/consent-modal.service';
import { Modal } from '../consent-modal/consent-modal.component';
import { ConsentWraper } from '../consent-wrapper/consent-wraper.component';
import { ConsentTrigger } from '../consent-trigger/consent-trigger.component';
import { GtagConsentService } from 'app/services/gtag-consent.service';
import { AppConfigService } from 'app/services/app-config.service';

export class MainComponent extends Component {
  private modalRef!: Modal;
  private consentDataService: ConsentDataService;
  private consentModalService: ConsentModalService;
  private gtagConsentService: GtagConsentService;
  private appConfigService: AppConfigService;

  constructor() {
    super({
      componentName: 'cookie-consent-app',
      componentType: ComponentType.Static,
    });

    this.consentDataService = ConsentDataService.getInstance();
    this.consentModalService = ConsentModalService.getInstance();
    this.gtagConsentService = GtagConsentService.getInstance();
    this.appConfigService = AppConfigService.getInstance();
  }

  initApp(): void {
    const cookieConsentDataFromApi: ApiCookieConsent | null = this.getCookieConsentApiData();

    if (cookieConsentDataFromApi) {
      this.consentDataService.setInitialCookieConsentData(cookieConsentDataFromApi);
      this.init();
    }
  }

  private init(): void {
    if (this.isPermissionsNeed()) {
      this.displayConsent();
    }

    if (this.appConfigService.getDisplayStickyTriggerState()) {
      this.addTriggerWidget();
    }

    this.addSubscriptions();
  }

  private addTriggerWidget(): void {
    const consentTriggerRef: ConsentTrigger = new ConsentTrigger();
    consentTriggerRef.renderWidget();
  }

  private addSubscriptions(): void {
    this.addSubscriptionForModalStateChanges();

    if (this.appConfigService.getIntegrationWithGTM()) {
      this.addSubscriptionForCookieConsentDataChanges();
    }
  }

  private addSubscriptionForModalStateChanges(): void {
    this.consentModalService.showModal$.subscribe(() => {
      if (this.modalRef === undefined) {
        this.displayConsent();
      } else {
        this.modalRef.show();
      }
    });
  }

  private addSubscriptionForCookieConsentDataChanges(): void {
    this.consentDataService.consentData$.pipe(filter((data) => data !== null)).subscribe((data) => {
      this.gtagConsentService.runGtagConsentMode(data as CookieConsent);
    });
  }

  private isPermissionsNeed(): boolean {
    if (this.consentDataService.isLocalDataOlderThanRemoteData()) {
      return true;
    }

    return false;
  }

  private displayConsent(): void {
    const consentWrapperRef = new ConsentWraper();

    this.modalRef = new Modal();
    this.modalRef.setContent(consentWrapperRef.hostView);
    this.appendChild(this.modalRef.hostView);

    this.modalRef.show();
  }

  private getCookieConsentApiData(): ApiCookieConsent | null {
    try {
      return this.hostView.dataset.cookieContent != null ? JSON.parse(this.hostView.dataset.cookieContent) : null;
    } catch (_) {
      return null;
    }
  }
}
