import { ConsentButtonsTheme } from 'app/models/consent-wrapper.model';
import { ConsentAltBox } from '../components/consent-alt-box/consent-alt-box.component';
import { ConsentBoxAltType } from '../components/consent-alt-box/consent-alt-box.model';
import { PublicApi } from '../models/api.model';
import { SupportedLanguages } from '../models/app-translations.model';
import { CookieConsentItem } from '../models/consent-data.model';
import { TranslateConfig } from '../models/translate.service.model';
import { AppInitializerService } from './app-initializer.service';
import { ConsentModalService } from './consent-modal.service';

declare global {
  interface Window {
    DPM_COOKIE_INFO: PublicApi;
  }
}

export class ApiService {
  private static instance: ApiService;
  private appInitializerService: AppInitializerService;
  private consentModalService: ConsentModalService;

  private constructor() {
    this.appInitializerService = AppInitializerService.getInstance();
    this.consentModalService = ConsentModalService.getInstance();
  }

  static getInstance(): ApiService {
    if (ApiService.instance === undefined) {
      ApiService.instance = new ApiService();
    }

    return ApiService.instance;
  }

  createPublicObject(): void {
    window.DPM_COOKIE_INFO = this.getInitialConfiguration();
  }

  updateConsentItems(items: CookieConsentItem[]): void {
    window.DPM_COOKIE_INFO.items = items;
  }

  private getInitialConfiguration(): PublicApi {
    return {
      items: [],
      setApiUrl: (url) => {
        this.setApiUrl(url);
      },
      setWidgetCommand: (command) => {
        this.setWidgetCommand(command);
      },
      setInterfaceLang: (lang) => {
        this.setInterfaceLang(lang);
      },
      getAlternative: (type) => {
        return this.getAlternativeContent(type);
      },
      setTranslationConfig: (translateConfig) => {
        this.setTranslationConfig(translateConfig);
      },
      setIntegrationWithGTM: (enable) => {
        this.setIntegrationWithGTM(enable);
      },
      displayStickyTrigger: (visible) => {
        this.displayStickyTrigger(visible);
      },
      displayRejectAllButton: (show) => {
        this.displayRejectAllButton(show);
      },
      displayCloseButton: (show) => {
        this.displayCloseButton(show);
      },
      setButtonsTheme: (theme) => {
        this.setButtonsTheme(theme);
      },
      addCustomCssClass: (className) => {
        this.addCustomCssClass(className);
      },
      showModal: () => {
        this.showModal();
      },
      run: () => {
        this.runApplication();
      },
      runAllNodes: () => {
        this.runAllNodes();
      },
      areConsentsVerified: () => {
        return this.areConsentsVerified();
      },
    };
  }

  private setApiUrl(url: string): void {
    this.appInitializerService.setApiUrl(url);
  }

  private setWidgetCommand(command: string): void {
    this.appInitializerService.setWidgetCommand(command);
  }

  private setInterfaceLang(lang: SupportedLanguages): void {
    this.appInitializerService.setInterfaceLang(lang);
  }

  private setTranslationConfig(config: TranslateConfig): void {
    this.appInitializerService.setTranslationConfig(config);
  }

  private setIntegrationWithGTM(enable: boolean): void {
    this.appInitializerService.setIntegrationWithGTM(enable);
  }

  private displayStickyTrigger(visible: boolean): void {
    this.appInitializerService.displayStickyTrigger(visible);
  }

  private displayRejectAllButton(show: boolean): void {
    this.appInitializerService.displayRejectAllButton(show);
  }

  private displayCloseButton(show: boolean): void {
    this.appInitializerService.displayCloseButton(show);
  }

  private setButtonsTheme(theme: ConsentButtonsTheme): void {
    this.appInitializerService.setButtonsTheme(theme);
  }

  private addCustomCssClass(className: string): void {
    this.appInitializerService.addCustomCssClass(className);
  }

  private getAlternativeContent(type: ConsentBoxAltType): HTMLElement {
    const altBox = new ConsentAltBox(type);

    return altBox.getHTMLContent();
  }

  private showModal(): void {
    this.consentModalService.showModal();
  }

  private runApplication(): void {
    this.appInitializerService.init();
  }

  private runAllNodes(): void {
    this.appInitializerService.runAllNodes();
  }

  private areConsentsVerified(): boolean {
    return this.appInitializerService.areConsentsVerified();
  }
}
