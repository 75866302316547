export interface TranslateConfig {
  external: boolean;
  url: string;
  fileName: string;
}

export enum TranslationsMode {
  Internal = 'internal',
  External = 'external',
}
