import { InternalMessagesConfig } from 'app/models/internal-messages.model';

const cookieLibMessagePrefix = 'Cookie Info Library:';

export const InternalMessages: InternalMessagesConfig = {
  SendConsentsLogToApiError: `${cookieLibMessagePrefix} There was a problem with saving the log to api`,
  MainScriptIsNotDefined: `${cookieLibMessagePrefix} You should define one main script with data-consent-type="main-js"`,
  WriteMethodDisableInfo: `${cookieLibMessagePrefix} document.write disabled`,
  WritelnMethodDisableInfo: `${cookieLibMessagePrefix} document.writeln disabled`,
  ComponentNotFound: (name: string) =>
    `${cookieLibMessagePrefix} Component HTML node [data-component="${name}"] not found`,
  EmptyTemplateForComponent: (name: string) =>
    `${cookieLibMessagePrefix} The dynamic component ${name} should have the template argument passed as a string`,
  FetchTranslationError: (
    message: string,
    url: string,
  ) => `${cookieLibMessagePrefix} \nAn error occurred while trying to get interface translations.
        \nMessage: ${message}
        \nRequested url: ${url}
    `,
};
