import { Subject } from 'rxjs';

export class ConsentModalService {
  private static instance: ConsentModalService;
  private refreshRequired = false;
  private activeBodyCssClass = 'consent-modal__body';

  readonly hideModal$: Subject<Date> = new Subject();
  readonly showModal$: Subject<Date> = new Subject();

  static getInstance(): ConsentModalService {
    if (ConsentModalService.instance === undefined) {
      ConsentModalService.instance = new ConsentModalService();
    }

    return ConsentModalService.instance;
  }

  /**
   * Public API method: hide modal
   */
  hideModal(): void {
    if (this.refreshRequired) {
      location.reload();
    } else {
      this.hideModal$.next(new Date());
    }
  }

  /**
   * Public API method: show modal
   */
  showModal(): void {
    this.showModal$.next(new Date());
  }

  /**
   * Public API method: set page refresh required
   */
  setRefreshRequireState(state: boolean): void {
    this.refreshRequired = state;
  }

  /**
   * Public API method: get page refresh required state
   */
  getRefreshRequiredState(): boolean {
    return this.refreshRequired;
  }

  /**
   * Public API method: disable page scrolling
   */
  disablePageScrolling() {
    document.body.classList.add(this.activeBodyCssClass);
  }

  /**
   * Public API method: enable page scrolling
   */
  enablePageScrolling() {
    document.body.classList.remove(this.activeBodyCssClass);
  }
}
