import { AppLabels, AppTranslationConfig, SupportedLanguages } from '../models/app-translations.model';
import { CookieConsentDetailsType } from '../models/consent-data.model';

export const AppTranslations: AppTranslationConfig = {
  [AppLabels.AcceptAllBtnLabel]: {
    [SupportedLanguages.Pl]: 'Zaakceptuj wszystkie',
    [SupportedLanguages.En]: 'Accept all',
    [SupportedLanguages.De]: 'Alle akzeptieren',
  },
  [AppLabels.RejectAllBtnLabel]: {
    [SupportedLanguages.Pl]: 'Odrzuć wszystkie',
    [SupportedLanguages.En]: 'Reject All',
    [SupportedLanguages.De]: 'Alles ablehnen',
  },
  [AppLabels.AltAgreeBtnLabel]: {
    [SupportedLanguages.Pl]: 'Zgadzam się',
    [SupportedLanguages.En]: 'Agree',
    [SupportedLanguages.De]: 'Zustimmen',
  },
  [AppLabels.AltGoogleMapsAgreement]: {
    [SupportedLanguages.Pl]: 'Używając Google Maps, zgadzasz się z polityką prywatności Google Maps.',
    [SupportedLanguages.En]: 'By using Google Maps, you accept Google Maps Policy.',
    [SupportedLanguages.De]:
      'Durch die Verwendung von Google Maps akzeptieren Sie die Datenschutzrichtlinien von Google Maps.',
  },
  [AppLabels.AltLearnMoreBtnLabel]: {
    [SupportedLanguages.Pl]: 'Dowiedz się więcej',
    [SupportedLanguages.En]: 'Learn more',
    [SupportedLanguages.De]: 'Finde mehr heraus',
  },
  [AppLabels.AltLinkedInAgreement]: {
    [SupportedLanguages.Pl]: 'Używając wtyczyki LinkedIn, zgadzasz się z polityką prywatności LinkedIn',
    [SupportedLanguages.En]: 'By using LinkedIn plugins, you accept LinkedIn Policy.',
    [SupportedLanguages.De]:
      'Durch die Verwendung des LinkedIn-Plugins akzeptieren Sie die Datenschutzrichtlinien von LinkedIn.',
  },
  [AppLabels.AltLoadBtnLabel]: {
    [SupportedLanguages.Pl]: 'Wczytaj film',
    [SupportedLanguages.En]: 'Load the video',
    [SupportedLanguages.De]: 'Laden Sie den Film',
  },
  [AppLabels.AltResourceLoadingMessage]: {
    [SupportedLanguages.Pl]: 'Trwa ładowanie zasobu...',
    [SupportedLanguages.En]: 'The resource is loading...',
    [SupportedLanguages.De]: 'Wird geladen…',
  },
  [AppLabels.AltVideoLoadingMessage]: {
    [SupportedLanguages.Pl]: 'Trwa wczytywanie filmu...',
    [SupportedLanguages.En]: 'The video is loading...',
    [SupportedLanguages.De]: 'Film wird geladen...',
  },
  [AppLabels.AltVimeoAgreement]: {
    [SupportedLanguages.Pl]: 'Ładując wideo, zgadzasz się z polityką prywatności Vimeo.',
    [SupportedLanguages.En]: 'By loading the video, you accept Vimeo Privacy Policy.',
    [SupportedLanguages.De]: 'Durch das Hochladen des Videos stimmen Sie den Datenschutzbestimmungen von Vimeo zu.',
  },
  [AppLabels.AltYoutubeAgreement]: {
    [SupportedLanguages.Pl]: 'Ładując wideo, zgadzasz się z polityką prywatności YouTube.',
    [SupportedLanguages.En]: 'By loading the video, you accept YouTube Privacy Policy.',
    [SupportedLanguages.De]: 'Durch das Hochladen des Videos stimmen Sie den Datenschutzbestimmungen von YouTube zu.',
  },
  [AppLabels.BackBtnLabel]: {
    [SupportedLanguages.Pl]: 'Wstecz',
    [SupportedLanguages.En]: 'Back',
    [SupportedLanguages.De]: 'Zurück',
  },
  [AppLabels.DetailHistoryTitle]: {
    [SupportedLanguages.Pl]: 'Historia',
    [SupportedLanguages.En]: 'History',
    [SupportedLanguages.De]: 'Verlauf',
  },
  [AppLabels.GeneralTitle]: {
    [SupportedLanguages.Pl]: 'Polityka prywatności Comarch',
    [SupportedLanguages.En]: 'Comarch Privacy Policy',
    [SupportedLanguages.De]: 'Datenschutzerklärung des Unternehmens',
  },
  [AppLabels.HistoryItemAccepted]: {
    [SupportedLanguages.Pl]: 'Tak',
    [SupportedLanguages.En]: 'Yes',
    [SupportedLanguages.De]: 'Ja',
  },
  [AppLabels.HistoryItemRefused]: {
    [SupportedLanguages.Pl]: 'Nie',
    [SupportedLanguages.En]: 'No',
    [SupportedLanguages.De]: 'Nein',
  },
  [AppLabels.ManageOptsBtnLabel]: {
    [SupportedLanguages.Pl]: 'Szczegóły',
    [SupportedLanguages.En]: 'Details',
    [SupportedLanguages.De]: 'Einstellungen anzeigen',
  },
  [AppLabels.SaveConsentBtnLabel]: {
    [SupportedLanguages.Pl]: 'Zaakceptuj wybrane',
    [SupportedLanguages.En]: 'Save your consents',
    [SupportedLanguages.De]: 'Auswahl speichern',
  },
  [CookieConsentDetailsType.Attributes]: {
    [SupportedLanguages.Pl]: 'Atrybuty danych',
    [SupportedLanguages.En]: 'Data attributes',
    [SupportedLanguages.De]: 'Datenattribute',
  },
  [CookieConsentDetailsType.Collected]: {
    [SupportedLanguages.Pl]: 'Informacje zebrane',
    [SupportedLanguages.En]: 'Collected data',
    [SupportedLanguages.De]: 'Erhobene Daten',
  },
  [CookieConsentDetailsType.Company]: {
    [SupportedLanguages.Pl]: 'Firma przetwarzająca dane',
    [SupportedLanguages.En]: 'Company which processes the data',
    [SupportedLanguages.De]: 'Datenverarbeitendes Unternehmen',
  },
  [CookieConsentDetailsType.DataPurposes]: {
    [SupportedLanguages.Pl]: 'Cele danych',
    [SupportedLanguages.En]: 'Data purposes',
    [SupportedLanguages.De]: 'Zweck der Datenverarbeitung',
  },
  [CookieConsentDetailsType.Distribution]: {
    [SupportedLanguages.Pl]: 'Dystrybucja do krajów trzecich',
    [SupportedLanguages.En]: 'Distribution to third countries',
    [SupportedLanguages.De]: 'Übermittlung in Drittländer / Mögliche Serverstandorte',
  },
  [CookieConsentDetailsType.Duration]: {
    [SupportedLanguages.Pl]: 'Czas przechowywania danych',
    [SupportedLanguages.En]: 'Data storage duration',
    [SupportedLanguages.De]: 'Dauer der Datenspeicherung',
  },
  [CookieConsentDetailsType.FurtherInformation]: {
    [SupportedLanguages.Pl]: 'Dalsze informacje i rezygnacja',
    [SupportedLanguages.En]: 'Further information and denial',
    [SupportedLanguages.De]: 'Weitere Informationen',
  },
  [CookieConsentDetailsType.LegalBasis]: {
    [SupportedLanguages.Pl]: 'Podstawa prawna',
    [SupportedLanguages.En]: 'Legal basis',
    [SupportedLanguages.De]: 'Rechtliche Grundlage',
  },
  [CookieConsentDetailsType.LocationOfProcessing]: {
    [SupportedLanguages.Pl]: 'Lokalizacja przetwarzania danych',
    [SupportedLanguages.En]: 'Location of data processing',
    [SupportedLanguages.De]: 'Ort der Datenverarbeitung',
  },
  [CookieConsentDetailsType.Name]: {
    [SupportedLanguages.Pl]: 'Nazwa',
    [SupportedLanguages.En]: 'Name',
    [SupportedLanguages.De]: 'Name',
  },
  [CookieConsentDetailsType.ProtectionOfficer]: {
    [SupportedLanguages.Pl]: 'Inspektor ochrony danych',
    [SupportedLanguages.En]: 'Data Protection Officer',
    [SupportedLanguages.De]: 'Datenschutzbeauftragter',
  },
  [CookieConsentDetailsType.Recipients]: {
    [SupportedLanguages.Pl]: 'Odbiorcy danych',
    [SupportedLanguages.En]: 'Data Recipients',
    [SupportedLanguages.De]: 'Datenempfänger',
  },
  [CookieConsentDetailsType.Technologies]: {
    [SupportedLanguages.Pl]: 'Zastosowane technologie',
    [SupportedLanguages.En]: 'Technologies used',
    [SupportedLanguages.De]: 'Verwendete Technologien',
  },
};
