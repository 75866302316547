import { CookieConsentItem } from '../models/consent-data.model';
import { ConsentModalService } from './consent-modal.service';
import { Observable, of, ReplaySubject } from 'rxjs';
import { InternalMessages } from 'app/config/internal-messages';

export class DomPreparationService {
  private static instance: DomPreparationService;
  private consentModalService: ConsentModalService;
  private readonly consentTypeAttrKey = 'data-consent-type';

  private constructor() {
    this.consentModalService = ConsentModalService.getInstance();
  }

  static getInstance(): DomPreparationService {
    if (DomPreparationService.instance === undefined) {
      DomPreparationService.instance = new DomPreparationService();
    }

    return DomPreparationService.instance;
  }

  /**
   * Public API method: Support for the main script from a rurek,
   * which includes libraries that should be loaded before others.
   */
  runMainScript(): Observable<boolean> {
    const disabledScripts: Element[] = this.getScripts(['main-js']);

    if (disabledScripts.length === 0) {
      console.error(InternalMessages.MainScriptIsNotDefined);

      return of(false);
    }

    return this.appendScriptInDocument(disabledScripts[0]);
  }

  /**
   * Public API method: run required scripts or others required elements
   */
  runStrictElements() {
    this.runConditionalElements(['strict']);
  }

  /**
   * Public API method: run all scripts and aothers elements
   */
  runAllElements() {
    const disabledScripts: Element[] = this.getAllDisabledScripts();
    this.runScripts(disabledScripts);

    const disabledFrames: Element[] = this.getAllDisabledFrames();
    this.runFrames(disabledFrames);

    const disabledImages: Element[] = this.getAllDisabledImages();
    this.runImages(disabledImages);
  }

  /**
   * Public API method: update DOM base on consent acceptance
   */
  updateDomStructure(items: CookieConsentItem[]): void {
    const accepted: string[] = [];
    const denied: string[] = [];

    items
      .filter((item: CookieConsentItem) => !item.required)
      .forEach((item: CookieConsentItem) => {
        if (item.accept) {
          accepted.push(item.id);
          accepted.push(item.tag);
        } else {
          denied.push(item.id);
          denied.push(item.tag);
        }
      });

    this.runConditionalElements(accepted);
    this.blockConditionalElements(denied);
  }

  private getAllDisabledScripts(): Element[] {
    const selector = `script[data-consent-type][type="text/plain"]`;

    return Array.from(document.querySelectorAll(selector)) ?? [];
  }

  private getAllDisabledFrames(): Element[] {
    const selector = `iframe[data-consent-type][data-src]`;

    return Array.from(document.querySelectorAll(selector)) ?? [];
  }

  private getAllDisabledImages(): Element[] {
    const selector = `img[data-consent-type][data-src]`;

    return Array.from(document.querySelectorAll(selector));
  }

  private runConditionalElements(categories: string[]): void {
    if (categories.length === 0) {
      return;
    }

    const disabledFrames: Element[] = this.getFrames(categories);
    const disabledScripts: Element[] = this.getScripts(categories);
    const disabledImages: Element[] = this.getImages(categories);

    if (disabledScripts.length > 0) {
      this.runScripts(disabledScripts);
    }

    if (disabledFrames.length > 0) {
      this.runFrames(disabledFrames);
    }

    if (disabledImages.length > 0) {
      this.runImages(disabledImages);
    }
  }

  private blockConditionalElements(categories: string[]): void {
    if (categories.length === 0) {
      return;
    }

    const enabledFrames: Element[] = this.getFrames(categories, true);
    const enabledScripts: Element[] = this.getScripts(categories, true);
    const enabledImages: Element[] = this.getImages(categories, true);

    if (enabledScripts.length > 0) {
      this.consentModalService.setRefreshRequireState(true);
    } else {
      this.consentModalService.setRefreshRequireState(false);
    }

    if (enabledFrames.length > 0) {
      this.stopFrames(enabledFrames);
    }

    if (enabledImages.length > 0) {
      this.stopImages(enabledImages);
    }
  }

  private getScripts(categories: string[], enabled = false): Element[] {
    const scriptsWithDataConsentType = document.querySelectorAll('script[data-consent-type]');
    const filteredCategories = categories.filter((category) => category != null);

    const filteredScripts = Array.from(scriptsWithDataConsentType).filter((script) => {
      const dataConsentTypes = this.getDataConsentTypeAttributes(script);
      const typeExistInCategories = this.isAtLeastOneElementOfArrayExistInAnotherArray(
        dataConsentTypes,
        filteredCategories,
      );
      const scriptTypeAttribute = script.getAttribute('type');
      const isDemandState = enabled ? scriptTypeAttribute === 'text/javascript' : scriptTypeAttribute === 'text/plain';

      if (typeExistInCategories && isDemandState) {
        return script;
      }
    });

    return filteredScripts;
  }

  private getFrames(categories: string[], enabled = false): Element[] {
    const framesWithDataConsentType = document.querySelectorAll('iframe[data-consent-type]');
    const filteredCategories = categories.filter((category) => category != null);

    const filteredFrames = Array.from(framesWithDataConsentType).filter((frame) => {
      const dataConsentTypes = this.getDataConsentTypeAttributes(frame);
      const typeExistInCategories = this.isAtLeastOneElementOfArrayExistInAnotherArray(
        dataConsentTypes,
        filteredCategories,
      );
      const isDemandState = enabled ? frame.getAttribute('src') != null : frame.getAttribute('data-src') != null;

      if (typeExistInCategories && isDemandState) {
        return frame;
      }
    });

    return filteredFrames;
  }

  private getImages(categories: string[], enabled = false): Element[] {
    const imagesWithDataConsentType = document.querySelectorAll('img[data-consent-type]');
    const filteredCategories = categories.filter((category) => category != null);

    const filteredImages = Array.from(imagesWithDataConsentType).filter((image) => {
      const dataConsentTypes = this.getDataConsentTypeAttributes(image);
      const typeExistInCategories = this.isAtLeastOneElementOfArrayExistInAnotherArray(
        dataConsentTypes,
        filteredCategories,
      );
      const isDemandState = enabled ? image.getAttribute('src') != null : image.getAttribute('data-src') != null;

      if (typeExistInCategories && isDemandState) {
        return image;
      }
    });

    return filteredImages;
  }

  private isAtLeastOneElementOfArrayExistInAnotherArray(firstArray: string[], secondArray: string[]): boolean {
    return firstArray.some((dataConsentType) => secondArray.includes(dataConsentType));
  }

  private getDataConsentTypeAttributes(element: Element): string[] {
    let types = element.getAttribute(this.consentTypeAttrKey)?.split(',') ?? [];

    if (types.length > 0) {
      types = types.map((consentType) => consentType.trim());
    }

    return types;
  }

  private runScripts(disabledScripts: Element[], index = 0): void {
    if (disabledScripts[index] !== undefined) {
      this.appendScriptInDocument(disabledScripts[index]).subscribe(() => {
        // Wait until previous script is loaded
        this.runScripts(disabledScripts, index + 1);
      });
    }
  }

  private appendScriptInDocument(node: Node): Observable<boolean> {
    const loaded$: ReplaySubject<boolean> = new ReplaySubject(1);

    const originalScript: HTMLScriptElement = node as HTMLScriptElement;
    const parentNode: Node | null = originalScript.parentNode;
    const newScript: HTMLScriptElement = document.createElement('script');

    Array.from(originalScript.attributes).forEach((attr: Attr) => {
      if (attr.name !== 'type') {
        newScript.setAttribute(attr.name, attr.value);
      }
    });

    newScript.type = 'text/javascript';

    if (originalScript.src !== '') {
      newScript.src = originalScript.src;
      newScript.async = false; // dynamic added script is async by default

      newScript.onload = () => {
        loaded$.next(true);
      };

      newScript.onerror = () => {
        loaded$.next(true); // skip damaged script (eg. 404)
      };
    } else {
      const inlineScript: Text = document.createTextNode(originalScript.innerText);
      newScript.appendChild(inlineScript);
      loaded$.next(true); // For inline scripts there's nothing to load (onLoad won't fire)
    }

    if (parentNode) {
      parentNode.replaceChild(newScript, originalScript);
    }

    return loaded$;
  }

  private runFrames(disabledFrames: Element[]): void {
    disabledFrames.forEach((element: Element) => {
      const originalIframe: HTMLIFrameElement = element as HTMLIFrameElement;
      const src: string | null = originalIframe.getAttribute('data-src');

      originalIframe.removeAttribute('data-src');
      if (src !== null) {
        originalIframe.src = src;
      }
    });
  }

  private runImages(disabledImages: Element[]): void {
    disabledImages.forEach((node: Element) => {
      const originalImage: HTMLImageElement = node as HTMLImageElement;
      const src: string | null = originalImage.getAttribute('data-src');

      originalImage.removeAttribute('data-src');
      if (src !== null) {
        originalImage.src = src;
      }
    });
  }

  private stopFrames(enabledFrames: Element[]): void {
    enabledFrames.forEach((node: Element) => {
      const originalIframe: HTMLIFrameElement = node as HTMLIFrameElement;
      const src: string = originalIframe.src;

      originalIframe.removeAttribute('src');
      originalIframe.setAttribute('data-src', src);
    });
  }

  private stopImages(enabledImages: Element[]): void {
    enabledImages.forEach((node: Element) => {
      const originalImage: HTMLImageElement = node as HTMLImageElement;
      const src: string = originalImage.src;

      originalImage.removeAttribute('src');
      originalImage.setAttribute('data-src', src);
    });
  }
}
