import { ConsentAltBoxTemplate } from './consent-alt-box.template';
import { ConsentAltBoxEvents, ConsentBoxAltType } from './consent-alt-box.model';
import { Component } from 'app/core/base-component';
import { ConsentModalService } from 'app/services/consent-modal.service';
import { ConsentWrapperService } from 'app/services/consent-wrapper.service';
import { ConsentMenuService } from 'app/services/consent-menu.service';
import { ConsentDataService } from 'app/services/consent-data.service';
import { ConsentWrapperStage } from 'app/models/consent-wrapper.model';
import { ChangeAgreementEventData } from '../consent-details/consent-details.model';

export class ConsentAltBox extends Component {
  private templateRef: ConsentAltBoxTemplate;
  private consentType: ConsentBoxAltType;
  private consentModalService: ConsentModalService;
  private consentWrapperService: ConsentWrapperService;
  private consentMenuService: ConsentMenuService;
  private consentDataService: ConsentDataService;

  constructor(consentType: ConsentBoxAltType) {
    super({
      componentName: 'consent-alt-box',
      componentClass: 'cookie-alt',
    });

    this.consentType = consentType;
    this.templateRef = new ConsentAltBoxTemplate();
    this.consentModalService = ConsentModalService.getInstance();
    this.consentWrapperService = ConsentWrapperService.getInstance();
    this.consentMenuService = ConsentMenuService.getInstance();
    this.consentDataService = ConsentDataService.getInstance();
    this.init();
  }

  /**
   * Public API method: return HTMLElement of component
   */
  getHTMLContent(): HTMLElement {
    return this.hostView;
  }

  private init(): void {
    this.renderContent(this.templateRef.getTemplate(this.consentType));
    this.addTemplateEventsSubscriptions();
  }

  private addTemplateEventsSubscriptions(): void {
    this.templateRef.events[ConsentAltBoxEvents.LearnMoreClick].subscribe(() => {
      this.showModalWitchConsentDetails();
    });

    this.templateRef.events[ConsentAltBoxEvents.RunClick].subscribe((consentType: ConsentBoxAltType) => {
      const consentData: ChangeAgreementEventData = {
        id: consentType,
        value: true,
      };

      this.consentDataService.updateConsentState(consentData, true);
      this.renderContent(this.templateRef.getTemplate(this.consentType, true));
    });
  }

  private showModalWitchConsentDetails(): void {
    this.consentWrapperService.switchStage(ConsentWrapperStage.Details);
    this.consentModalService.showModal();
    this.consentMenuService.setActiveMenu(this.consentType);
  }
}
