import { CookieConsentCategory } from 'app/models/consent-data.model';
import { TemplateResult, html } from 'lit-html';

export function getMenuGroupTemplate(category: CookieConsentCategory): TemplateResult {
  return html`
    <div class="consent-modal__menu-group">
      <span class="consent-modal__menu-group-title">${category.name}</span>
      <ul data-content="menu-items"></ul>
    </div>
  `;
}
