import { Component } from 'app/core/base-component';
import { ConsentModalService } from 'app/services/consent-modal.service';
import { TemplateRef } from 'app/core/models/template.model';
import { ConsentModalTemplate } from './consent-modal.template';
import { ConsentModalEvent } from './consent-modal.model';
import { AppConfigService } from 'app/services/app-config.service';

export class Modal extends Component {
  private consentModalService: ConsentModalService;
  private templateRef: TemplateRef;
  private visibilityToggleClass = 'visible';
  private appConfigService: AppConfigService;
  private customCssClassName = '';
  private displayCloseButton = false;

  constructor() {
    super({
      componentName: 'consent-modal',
      componentClass: 'consent-modal',
    });

    this.consentModalService = ConsentModalService.getInstance();
    this.appConfigService = AppConfigService.getInstance();
    this.templateRef = new ConsentModalTemplate();

    this.init();
  }

  show(): void {
    this.consentModalService.disablePageScrolling();
    this.addHostViewClass(this.visibilityToggleClass);
  }

  hide(): void {
    this.consentModalService.enablePageScrolling();

    if (this.consentModalService.getRefreshRequiredState()) {
      location.reload();
    } else {
      this.removeHostViewClass(this.visibilityToggleClass);
    }
  }

  setContent(element: HTMLElement): void {
    const mainContainer: HTMLElement | null = this.hostView.querySelector('[data-container="content"]');

    if (mainContainer) {
      mainContainer.appendChild(element);
    }
  }

  private init(): void {
    this.customCssClassName = this.appConfigService.getCustomCssClass();
    this.displayCloseButton = this.appConfigService.getDisplayCloseButtonState();

    if (this.customCssClassName?.length > 0) {
      this.addHostViewClass(this.customCssClassName);
    }

    this.addSubscriptions();
    this.renderContent(
      this.templateRef.getTemplate({
        displayCloseButton: this.displayCloseButton,
      }),
    );
  }

  private addSubscriptions(): void {
    this.consentModalService.hideModal$.subscribe(() => {
      this.hide();
    });

    this.templateRef.events[ConsentModalEvent.CloseModal].subscribe(() => {
      this.hide();
    });
  }
}
