import { TemplateEvents } from './models/template.model';
import { Subject } from 'rxjs';
import { TranslateService } from '../services/translate.service';

export class BaseTemplate {
  events: TemplateEvents = {};

  registerEvent<T>(key: string, event: Subject<T>): void {
    this.events[key] = event;
  }

  translate(string: string): string {
    return TranslateService.getInstance().translate(string as keyof object);
  }
}
