import { Component } from 'app/core/base-component';
import { CookieConsentCategory, CookieConsentItem } from 'app/models/consent-data.model';
import { ComponentHostViewTag } from 'app/core/models/component.model';
import { getMenuGroupTemplate } from './menu-group.template';
import { MenuItem } from '../menu-item/menu-item.component';

export class MenuGroup extends Component {
  private category: CookieConsentCategory;

  constructor(category: CookieConsentCategory) {
    super({
      componentName: 'menu-group',
      componentHostViewTag: ComponentHostViewTag.Li,
    });

    this.category = category;
    this.init();
  }

  private init(): void {
    this.renderContent(getMenuGroupTemplate(this.category));

    if (!this.category.items) {
      return;
    }

    this.category.items.forEach((item: CookieConsentItem) => {
      const itemRef: MenuItem = new MenuItem(item);
      const menuItemsElement = this.hostView.querySelector('[data-content="menu-items"]');
      if (menuItemsElement) {
        menuItemsElement.appendChild(itemRef.hostView);
      }
    });
  }
}
