import { Component } from 'app/core/base-component';
import { ComponentHostViewTag } from 'app/core/models/component.model';
import { CookieConsentItem } from 'app/models/consent-data.model';
import { ConsentMenuService } from 'app/services/consent-menu.service';
import { getMenuItemTemplate } from './menu-item.template';

export class MenuItem extends Component {
  private menuItem: CookieConsentItem;
  private consentMenuService: ConsentMenuService;

  constructor(menuItem: CookieConsentItem) {
    super({
      componentName: 'menu-item',
      componentHostViewTag: ComponentHostViewTag.Li,
      componentClass: 'consent-modal__menu-item',
    });

    this.menuItem = menuItem;
    this.consentMenuService = ConsentMenuService.getInstance();
    this.init();
  }

  private init(): void {
    this.addListeners();
    this.renderContent(getMenuItemTemplate(this.menuItem));
  }

  private addListeners(): void {
    this.hostView.addEventListener('click', () => {
      if (!this.hostView.classList.contains('active')) {
        this.consentMenuService.setActiveMenu(this.menuItem.id);
      }
    });

    this.consentMenuService.activeMenu$.subscribe((id: string | null) => {
      if (id === this.menuItem.id) {
        this.setAsActive();
      } else {
        this.unsetAsActive();
      }
    });
  }

  private setAsActive(): void {
    this.hostView.classList.add('active');
  }

  private unsetAsActive(): void {
    this.hostView.classList.remove('active');
  }
}
