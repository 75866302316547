import { Subscription, fromEvent, throttleTime } from 'rxjs';

import { ConsentDetailsTemplate } from './consent-details.template';
import { ConsentDetailsEvents, ChangeAgreementEventData } from './consent-details.model';
import { Component } from 'app/core/base-component';
import { CookieConsent, CookieConsentItem } from 'app/models/consent-data.model';
import { ConsentDataService } from 'app/services/consent-data.service';
import { ConsentMenuService } from 'app/services/consent-menu.service';

export class ConsentDetails extends Component {
  private cookieConsentData!: CookieConsent;
  private consentDataService: ConsentDataService;
  private consentMenuService: ConsentMenuService;
  private templateRef: ConsentDetailsTemplate;
  private consentSubscription!: Subscription;

  constructor() {
    super({
      componentName: 'consent-details',
      componentClass: 'consent-modal__details',
    });

    this.consentDataService = ConsentDataService.getInstance();
    this.consentMenuService = ConsentMenuService.getInstance();
    this.templateRef = new ConsentDetailsTemplate();
    this.addSubscriptions();
    this.registerScrollEvent();
  }

  private registerScrollEvent() {
    const scroll$ = fromEvent(this.hostView, 'scroll');
    const offsetFromTop = 200;
    const throttleDelay = 200;

    scroll$.pipe(throttleTime(throttleDelay)).subscribe((event: Event) => {
      const target = event.target as HTMLElement;
      const activeElement = Array.from(target.querySelectorAll('.consent-modal__details-data')).find(
        (element: Element) => {
          const htmlElement: HTMLElement = element as HTMLElement;

          return htmlElement.offsetTop + htmlElement.offsetHeight - offsetFromTop - target.scrollTop > 0;
        },
      );

      if (activeElement !== undefined) {
        const activeElementId = activeElement.id;
        const prefix = 'consent-detail-';
        const itemId = activeElementId.replace(prefix, '');

        this.consentMenuService.activeMenu$.next(itemId);
      }
    });
  }

  private addSubscriptions(): void {
    this.consentDataService.consentData$.subscribe((data: CookieConsent | null) => {
      if (data) {
        this.cookieConsentData = data;
        this.renderDetails();
        if (this.consentSubscription === undefined) {
          this.consentSubscription = this.getRenderDetailsSubscription();
        }
      }
    });

    this.consentDataService.currentActiveItem$.subscribe((item: CookieConsentItem | null) => {
      if (item) {
        const currentItem: HTMLElement | null = this.hostView.querySelector(`#consent-detail-${item.id}`);

        if (currentItem) {
          currentItem.scrollIntoView({
            behavior: 'smooth',
          });
        }
      }
    });
  }

  private renderDetails(): void {
    this.renderContent(this.templateRef.getTemplate(this.cookieConsentData));
  }

  private getRenderDetailsSubscription(): Subscription {
    return this.templateRef.events[ConsentDetailsEvents.ChangeAgreement].subscribe((data: ChangeAgreementEventData) => {
      this.consentDataService.updateConsentState(data, false);
    });
  }
}
