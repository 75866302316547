import { Subject } from 'rxjs';
import { TemplateResult, html, nothing } from 'lit-html';

import { BaseTemplate } from 'app/core/base-template';
import { TemplateRef } from 'app/core/models/template.model';
import { CookieConsent } from 'app/models/consent-data.model';
import { AppLabels } from 'app/models/app-translations.model';
import { ConsentGeneralEvent } from './consent-general.model';
import { ConsentButtonsTheme } from 'app/models/consent-wrapper.model';
import { getButtonClassNameByUsingTheme } from 'app/helpers/get-button-class';

export class ConsentGeneralTemplate extends BaseTemplate implements TemplateRef {
  private acceptAll$!: Subject<Date>;
  private manageOptions$!: Subject<Date>;

  constructor() {
    super();
    this.registerEvents();
  }

  getTemplate(elements: { data: CookieConsent; buttonsTheme: ConsentButtonsTheme }): TemplateResult {
    const theme = elements.buttonsTheme;
    const btnClass = getButtonClassNameByUsingTheme(theme);

    return html`
      <div class="consent-modal__general-header">${this.translate(AppLabels.GeneralTitle)}</div>
      <div class="consent-modal__wysiwyg" .innerHTML=${elements.data.private_policy}></div>
      <div class="consent-modal__general-btn">
        <button class="c-btn" @click="${this.acceptAll.bind(this)}">
          ${this.translate(AppLabels.AcceptAllBtnLabel)}
        </button>
        <button
          class="${btnClass} ${theme === ConsentButtonsTheme.Highlighted ? 'c-btn--icon-right' : ''}"
          @click="${this.manageOptions.bind(this)}"
        >
          ${this.translate(AppLabels.ManageOptsBtnLabel)}
          ${theme === ConsentButtonsTheme.Highlighted
            ? html`<i class="cm-icon-arrow cm-icon-arrow--right"></i>`
            : nothing}
        </button>
      </div>
    `;
  }

  private registerEvents(): void {
    this.registerEvent(ConsentGeneralEvent.AcceptAll, (this.acceptAll$ = new Subject()));
    this.registerEvent(ConsentGeneralEvent.ManageOptions, (this.manageOptions$ = new Subject()));
  }

  private acceptAll(): void {
    this.acceptAll$.next(new Date());
  }

  private manageOptions(): void {
    this.manageOptions$.next(new Date());
  }
}
