import { ComponentType, ComponentParamsConfig, ComponentHostViewTag } from './models/component.model';
import { TemplateResult, render } from 'lit-html';
import { InternalMessages } from 'app/config/internal-messages';

export class Component {
  private name: string;
  private type: ComponentType;
  private template: string;
  private componentHostViewTag: ComponentHostViewTag;
  private componentClass: string;

  hostView: HTMLElement;

  constructor(params: ComponentParamsConfig) {
    this.name = params.componentName;
    this.type =
      params.componentType !== undefined && Object.values(ComponentType).includes(params.componentType)
        ? params.componentType
        : ComponentType.Dynamic;
    this.componentHostViewTag =
      params.componentHostViewTag !== undefined &&
      Object.values(ComponentHostViewTag).includes(params.componentHostViewTag)
        ? params.componentHostViewTag
        : ComponentHostViewTag.Div;
    this.componentClass = params.componentClass != null ? params.componentClass : '';
    (this.template = this.getHostViewTemplate()), (this.hostView = this.getHostView());

    if (this.hostView === undefined) {
      throw new Error(`Component HTML node [data-component="${this.name}"] not found`);
    }
  }

  /**
   * Public API method: append child component
   */
  appendChild(hostView: HTMLElement, element?: HTMLElement): void {
    const holder = element ? element : this.hostView;
    holder.appendChild(hostView);
  }

  /**
   * Public API method: render component content
   */
  renderContent(template: TemplateResult): void {
    render(template, this.hostView);
  }

  /**
   * Public API method: add custom classes to component main tag
   */
  addHostViewClass(className: string): void {
    const classNames = className.split(' ');

    classNames.forEach((name) => {
      this.hostView.classList.add(name);
    });
  }

  /**
   * Public API method: remove custom class grom component host view tag
   */
  removeHostViewClass(className: string): void {
    this.hostView.classList.remove(className);
  }

  /**
   * Public API method: clear content
   */
  clearContent(element?: HTMLElement): void {
    const clearing: HTMLElement | null = element ? element : this.hostView;

    clearing.innerHTML = '';
  }

  private getHostViewTemplate(): string {
    return `
            <${this.componentHostViewTag}
                data-component="${this.name}"
                ${this.componentClass.length > 0 ? 'class="' + this.componentClass + '"' : ''}
            ></${this.componentHostViewTag}>`;
  }

  private getHostView(): HTMLElement {
    switch (this.type) {
      case ComponentType.Static:
        return this.getStaticComponentDOMReference();
      case ComponentType.Dynamic:
        return this.getDynamicComponentDOMReference();
    }
  }

  private getStaticComponentDOMReference(): HTMLElement {
    try {
      return document.querySelector(`[data-component="${this.name}"]`) as HTMLElement;
    } catch (error) {
      console.error(InternalMessages.ComponentNotFound(this.name));
      return null as unknown as HTMLElement;
    }
  }

  private getDynamicComponentDOMReference(): HTMLElement {
    const parser = new DOMParser();
    if (this.template === undefined) {
      throw new Error(InternalMessages.EmptyTemplateForComponent(this.name));
    }
    const componentHTML = parser.parseFromString(this.template, 'text/html').body.childNodes[0] as HTMLElement;

    return componentHTML;
  }
}
