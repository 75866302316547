export enum ConsentAltBoxEvents {
  LearnMoreClick = 'learnMoreClick',
  RunClick = 'runClick',
}

export enum ConsentBoxAltType {
  Youtube = 'youtube',
  Vimeo = 'vimeo',
  LinkedIn = 'linkedin',
  GoogleMaps = 'google-maps',
}
