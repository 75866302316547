import { TemplateResult, html, nothing } from 'lit-html';
import { Subject } from 'rxjs';

import { BaseTemplate } from 'app/core/base-template';
import { TemplateRef } from 'app/core/models/template.model';
import { ConsentModalEvent } from './consent-modal.model';

export class ConsentModalTemplate extends BaseTemplate implements TemplateRef {
  private closeModal$: Subject<Date>;

  constructor() {
    super();
    this.registerEvent(ConsentModalEvent.CloseModal, (this.closeModal$ = new Subject()));
  }

  getTemplate(data: { displayCloseButton: boolean }): TemplateResult {
    return html`
      <div class="consent-modal__content" data-container="content">
        ${data.displayCloseButton
          ? html`<div @click="${this.emitCloseModalEvent.bind(this)}" class="consent-modal__close"></div>`
          : nothing}
      </div>
    `;
  }

  private emitCloseModalEvent(): void {
    this.closeModal$.next(new Date());
  }
}
