import { html, TemplateResult } from 'lit-html';
import { live } from 'lit-html/directives/live.js';
import { Subject } from 'rxjs';

import {
  CookieConsentItem,
  CookieConsent,
  CookieConsentCategory,
  CookieConsentItemHistory,
  CookieConsentDetailsType,
} from 'app/models/consent-data.model';
import { TemplateRef } from 'app/core/models/template.model';
import { BaseTemplate } from 'app/core/base-template';
import { ConsentDetailsEvents, ChangeAgreementEventData } from './consent-details.model';
import { AppLabels } from 'app/models/app-translations.model';
import { isHtmlContainText } from 'app/helpers/is-html-contain-text';

export class ConsentDetailsTemplate extends BaseTemplate implements TemplateRef {
  private categoryTemplates: TemplateResult[] = [];
  private changeAgreement$!: Subject<ChangeAgreementEventData>;

  constructor() {
    super();
    this.registerEvents();
  }

  getTemplate(data: CookieConsent): TemplateResult {
    this.categoryTemplates = [];

    data.category.map((category: CookieConsentCategory) => {
      if (category.items && category.items.length > 0) {
        this.categoryTemplates.push(html`${this.getCategoryTemplate(category)}`);
      }
    });

    return html` ${this.getDetailsDescription(data.details_description)} ${this.categoryTemplates} `;
  }

  private onChange(event: Event) {
    const sourceElement: HTMLInputElement = event.target as HTMLInputElement;

    this.changeAgreement$.next({
      id: sourceElement.dataset['id'] != null ? sourceElement.dataset['id'] : '',
      value: sourceElement.checked,
    });
  }

  private registerEvents(): void {
    this.registerEvent(ConsentDetailsEvents.ChangeAgreement, (this.changeAgreement$ = new Subject()));
  }

  private getDetailsDescription(htmlString: string): TemplateResult {
    if (isHtmlContainText(htmlString)) {
      return html` <div class="consent-modal__details-desc consent-modal__wysiwyg" .innerHTML=${htmlString}></div> `;
    }

    return html``;
  }

  private getItemFieldTemplate(name: CookieConsentDetailsType, field: string): TemplateResult {
    if (field.length > 0) {
      return html`
        <div class="consent-modal__item-property">
            <h5 class="consent-modal__item-property-name">${this.translate(name)}</h4>
            <div class="consent-modal__item-property-desc" .innerHTML=${field}></div>
        </div>
      `;
    }

    return html``;
  }

  private getItemTemplate(item: CookieConsentItem): TemplateResult {
    return html`
      <div class="consent-modal__details-data" id="consent-detail-${item.id}">
        <div>
          <div class="consent-modal__item-data">
            <h4 class="consent-modal__item-name">${item.name}</h4>
            <div>
              <label class="switch ${item.required ? 'disabled' : ''}">
                <input
                  type="checkbox"
                  class="switch__original-input"
                  @change="${this.onChange.bind(this)}"
                  data-id="${item.id}"
                  .checked=${item.required ? true : live(item.accept)}
                  .disabled="${item.required}"
                />
                <span class="switch__slider"></span>
              </label>
            </div>
          </div>
          <div class="consent-modal__item-property-desc" .innerHTML=${item.description}></div>
        </div>

        <div>${this.getItemsFieldTemplates(item)}</div>

        ${this.getHistoryTemplate(item)}
      </div>
    `;
  }

  private getItemsFieldTemplates(item: CookieConsentItem): TemplateResult {
    const excludeFields: string[] = [
      'id',
      'category',
      'description',
      'categoryEntity',
      'required',
      'active',
      'accept',
      'history',
      'tag',
    ];
    const fields = Object.keys(item).filter((field: string) => !excludeFields.includes(field));

    const templates: TemplateResult[] = [];

    fields.forEach((field: string) => {
      this.getItemFieldTemplate(field as CookieConsentDetailsType, item[field as keyof object]);

      templates.push(
        html`${this.getItemFieldTemplate(field as CookieConsentDetailsType, item[field as keyof object])}`,
      );
    });

    return html`${templates}`;
  }

  private getHistoryTemplate(item: CookieConsentItem): TemplateResult {
    if (item.history.length === 0 || item.required) {
      return html``;
    }

    const itemTemplates: TemplateResult[] = [];

    item.history.forEach((item: CookieConsentItemHistory) => itemTemplates.push(this.getHistoryItemTemplate(item)));

    return html`
      <div>
        <h5 class="consent-modal__item-property-name consent-modal__item-history">
          ${this.translate(AppLabels.DetailHistoryTitle)}
        </h5>
        ${itemTemplates}
      </div>
    `;
  }

  private getHistoryItemTemplate(item: CookieConsentItemHistory): TemplateResult {
    return html`
      <div class="consent-modal__item-history-wrap">
        <div class="consent-modal__item-history-state">
          <div class="consent-modal__item-history-state-icon ${item.approval.value ? 'active' : ''}"></div>
          ${item.approval.value
            ? this.translate(AppLabels.HistoryItemAccepted)
            : this.translate(AppLabels.HistoryItemRefused)}
        </div>
        <div>
          <p class="consent-modal__item-history-date">${new Date(item.date).toLocaleString()}</p>
        </div>
      </div>
    `;
  }

  private getCategoryTemplate(category: CookieConsentCategory): TemplateResult {
    return html`
      <div class="consent-modal__details-section">
        <div class="consent-modal__details-category">
          <h3 class="consent-modal__category-header">${category.name}</h3>
          <div class="consent-modal__category-desc consent-modal__wysiwyg" .innerHTML=${category.description}></div>
        </div>

        ${category.items
          ? category.items.map((item: CookieConsentItem) => {
              return this.getItemTemplate(item);
            })
          : ''}
      </div>
    `;
  }
}
