import { ConsentStorageData } from '../models/consent-data.model';

export class ConsentStorageService {
  private static instance: ConsentStorageService;

  private storageKey = 'cookie_consent';

  static getInstance(): ConsentStorageService {
    if (ConsentStorageService.instance === undefined) {
      ConsentStorageService.instance = new ConsentStorageService();
    }

    return ConsentStorageService.instance;
  }

  /**
   * Public API method: get consent data from client storage
   */
  getLocalSavedData(): ConsentStorageData | null {
    return this.getDataFromStorage();
  }

  /**
   * Public API method: save consent data in client storage
   */
  saveLocalData(data: ConsentStorageData): void {
    this.saveDataInStorage(data);
  }

  private saveDataInStorage(data: ConsentStorageData): void {
    localStorage.setItem(this.storageKey, JSON.stringify(data));
  }

  private getDataFromStorage(): ConsentStorageData | null {
    try {
      return localStorage.getItem(this.storageKey) != null
        ? JSON.parse(localStorage.getItem(this.storageKey) as string)
        : null;
    } catch (_) {
      return null;
    }
  }
}
