import { filter } from 'rxjs';

import { Component } from 'app/core/base-component';
import { CookieConsent, CookieConsentCategory } from 'app/models/consent-data.model';
import { ConsentDataService } from 'app/services/consent-data.service';
import { ConsentMenuTemplate } from './consent-menu.template';
import { ConsentMenuEvents } from './consent-menu.model';
import { MenuGroup } from './components/menu-group/menu-group.component';

export class ConsentMenu extends Component {
  private data!: CookieConsent;
  private consentDataService!: ConsentDataService;
  private templateRef!: ConsentMenuTemplate;
  private activeMenu = true;

  constructor() {
    super({
      componentName: 'consent-menu',
      componentClass: 'consent-modal__menu',
    });

    this.init();
  }

  private init(): void {
    this.consentDataService = ConsentDataService.getInstance();
    this.templateRef = new ConsentMenuTemplate();
    this.addSubscriptions();
  }

  private displayGroups(): void {
    const groupContainer = this.hostView.querySelector('[data-content="menu-group"]');
    if (groupContainer) {
      groupContainer.innerHTML = '';

      this.data.category.map((category: CookieConsentCategory) => {
        const menuGroupRef: MenuGroup = new MenuGroup(category);
        groupContainer.appendChild(menuGroupRef.hostView);
      });
    }
  }

  private addSubscriptions(): void {
    this.templateRef.events[ConsentMenuEvents.ToggleMenu].subscribe(() => {
      this.toggleMenu();
    });

    this.consentDataService.consentData$.pipe(filter(Boolean)).subscribe((data: CookieConsent) => {
      this.data = data;
      this.refreshComponent();
    });
  }

  private refreshComponent(): void {
    this.renderContent(this.templateRef.getTemplate());
    this.displayGroups();
  }

  private toggleMenu(): void {
    this.activeMenu = !this.activeMenu;

    if (this.activeMenu) {
      this.removeHostViewClass('hidden');
    } else {
      this.addHostViewClass('hidden');
    }

    this.renderContent(this.templateRef.getTemplate());
  }
}
