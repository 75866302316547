import { TemplateResult } from 'lit-html';
import { Subscription } from 'rxjs';

import { Component } from 'app/core/base-component';
import { ConsentDataService } from 'app/services/consent-data.service';
import { ConsentWrapperService } from 'app/services/consent-wrapper.service';
import { ConsentButtonsTheme, ConsentWrapperStage } from 'app/models/consent-wrapper.model';
import { ConsentMenu } from '../consent-menu/consent-menu.component';
import { ConsentDetails } from '../consent-details/consent-details.component';
import { ConsentGeneral } from '../consent-general/consent-general.component';
import { ConsentWrapperTemplate } from './consent-wrapper.template';
import { ConsentWrapperEvent } from './consent-wrapper.model';
import { AppConfigService } from 'app/services/app-config.service';

export class ConsentWraper extends Component {
  private consentWrapperService: ConsentWrapperService;
  private consentDataService: ConsentDataService;
  private appConfigService: AppConfigService;
  private templateRef: ConsentWrapperTemplate;
  private consentMenuRef!: ConsentMenu;
  private consentDetailsRef!: ConsentDetails;
  private eventSubscription!: Subscription;
  private showRejectAllButton!: boolean;
  private buttonsTheme!: ConsentButtonsTheme;

  constructor() {
    super({
      componentName: 'consent-wrapper',
      componentClass: 'consent-modal__wrapper',
    });

    this.templateRef = new ConsentWrapperTemplate();
    this.consentWrapperService = ConsentWrapperService.getInstance();
    this.consentDataService = ConsentDataService.getInstance();
    this.appConfigService = AppConfigService.getInstance();
    this.init();
  }

  private init(): void {
    this.showRejectAllButton = this.appConfigService.getDisplayRejectAllButtonState();
    this.buttonsTheme = this.appConfigService.getButtonsTheme();

    this.addSubscriptions();
  }

  private refreshView(stage: ConsentWrapperStage = ConsentWrapperStage.General): void {
    switch (stage) {
      case ConsentWrapperStage.General:
        this.generateGeneralInformation();
        break;

      case ConsentWrapperStage.Details:
        this.generateConsentDetails();
        break;
    }
  }

  private addSubscriptions(): void {
    this.consentWrapperService.currentStage$.subscribe((stage: ConsentWrapperStage) => {
      this.refreshView(stage);
    });
  }

  private addEventSubscriptions(): void {
    if (this.eventSubscription === undefined) {
      this.eventSubscription = new Subscription();

      this.eventSubscription.add(
        this.templateRef.events[ConsentWrapperEvent.AcceptAll].subscribe(() => {
          this.consentDataService.acceptAllConsents();
        }),
      );

      this.eventSubscription.add(
        this.templateRef.events[ConsentWrapperEvent.RejectAll].subscribe(() => {
          this.consentDataService.declineAllConsents();
        }),
      );

      this.eventSubscription.add(
        this.templateRef.events[ConsentWrapperEvent.SaveConsents].subscribe(() => {
          this.consentDataService.saveConsents();
        }),
      );

      this.eventSubscription.add(
        this.templateRef.events[ConsentWrapperEvent.GoBack].subscribe(() => {
          this.consentWrapperService.switchStage(ConsentWrapperStage.General);
        }),
      );
    }
  }

  private generateGeneralInformation(): void {
    const consentGeneralRef: ConsentGeneral = new ConsentGeneral();
    const template: TemplateResult = this.templateRef.getTemplate({
      elements: [consentGeneralRef.hostView],
      stage: ConsentWrapperStage.General,
      options: {
        showRejectAllBtn: this.showRejectAllButton,
        buttonsTheme: this.buttonsTheme,
      },
    });

    this.renderContent(template);
  }

  private generateConsentDetails(): void {
    this.consentMenuRef = this.consentMenuRef !== undefined ? this.consentMenuRef : new ConsentMenu();
    this.consentDetailsRef = this.consentDetailsRef !== undefined ? this.consentDetailsRef : new ConsentDetails();

    const detailsWrapper: TemplateResult = this.templateRef.getTemplate({
      elements: [this.consentMenuRef.hostView, this.consentDetailsRef.hostView],
      stage: ConsentWrapperStage.Details,
      options: {
        showRejectAllBtn: this.showRejectAllButton,
        buttonsTheme: this.buttonsTheme,
      },
    });

    this.renderContent(detailsWrapper);
    this.addEventSubscriptions();
  }
}
