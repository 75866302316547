import { filter, Subscription } from 'rxjs';

import { Component } from 'app/core/base-component';
import { ConsentGeneralTemplate } from './consent-general.template';
import { ConsentDataService } from 'app/services/consent-data.service';
import { CookieConsent } from 'app/models/consent-data.model';
import { ConsentGeneralEvent } from './consent-general.model';
import { ConsentWrapperService } from 'app/services/consent-wrapper.service';
import { ConsentButtonsTheme, ConsentWrapperStage } from 'app/models/consent-wrapper.model';
import { AppConfigService } from 'app/services/app-config.service';

export class ConsentGeneral extends Component {
  private templateRef: ConsentGeneralTemplate;
  private consentDataService: ConsentDataService;
  private consentWrapperService: ConsentWrapperService;
  private appConfigService: AppConfigService;
  private data!: CookieConsent;
  private eventSubscription!: Subscription;
  private isDeclineConsentsEventRegistered = false;
  private buttonsTheme!: ConsentButtonsTheme;

  constructor() {
    super({
      componentName: 'consent-general',
      componentClass: 'consent-modal__general',
    });

    this.templateRef = new ConsentGeneralTemplate();
    this.consentDataService = ConsentDataService.getInstance();
    this.consentWrapperService = ConsentWrapperService.getInstance();
    this.appConfigService = AppConfigService.getInstance();
    this.buttonsTheme = this.appConfigService.getButtonsTheme();

    this.addSubscription();
  }

  private addSubscription(): void {
    this.consentDataService.consentData$.pipe(filter(Boolean)).subscribe((data: CookieConsent) => {
      this.data = data;

      this.renderContent(
        this.templateRef.getTemplate({
          data: this.data,
          buttonsTheme: this.buttonsTheme,
        }),
      );

      this.addEventsSubscription();

      if (!this.isDeclineConsentsEventRegistered) {
        setTimeout(() => this.registerDeclineConsentsClickEvent());
      }
    });
  }

  private registerDeclineConsentsClickEvent() {
    document.querySelectorAll('.consent-modal__decline-consents-btn').forEach((inlineBtnElement: Element) => {
      const eventListener: EventListener = this.getDeclineConsentsEventListener();

      inlineBtnElement.addEventListener('click', eventListener);
      this.isDeclineConsentsEventRegistered = true;
    });
  }

  private getDeclineConsentsEventListener(): EventListener {
    return (event: Event) => {
      event.preventDefault();
      event.stopPropagation();

      this.consentDataService.declineAllConsents();
    };
  }

  private addEventsSubscription(): void {
    if (this.eventSubscription === undefined) {
      this.eventSubscription = new Subscription();

      this.eventSubscription.add(
        this.templateRef.events[ConsentGeneralEvent.AcceptAll].subscribe(() => {
          this.consentDataService.acceptAllConsents();
        }),
      );

      this.eventSubscription.add(
        this.templateRef.events[ConsentGeneralEvent.ManageOptions].subscribe(() => {
          this.consentWrapperService.switchStage(ConsentWrapperStage.Details);
        }),
      );
    }
  }
}
