import {
  ConsentStorageDataApproval,
  CookieConsent,
  CookieConsentHistory,
  CookieConsentItem,
} from '../models/consent-data.model';

export class ConsentHistoryService {
  private static instance: ConsentHistoryService;
  private currentHistory: CookieConsentHistory[] = [];

  static getInstance(): ConsentHistoryService {
    if (ConsentHistoryService.instance === undefined) {
      ConsentHistoryService.instance = new ConsentHistoryService();
    }

    return ConsentHistoryService.instance;
  }

  /**
   * Public API method: append history to consent items
   */
  appendHistoryToItems(history: CookieConsentHistory[], consentsData: CookieConsent): CookieConsent {
    if (history === undefined) {
      return consentsData;
    }

    consentsData.items.forEach((item: CookieConsentItem) => (item.history = []));

    history.forEach((historyItem: CookieConsentHistory) => {
      const date: Date = historyItem.date;
      let settings: ConsentStorageDataApproval[] = historyItem.settings;

      // TODO: remove temporary fix
      if (typeof historyItem.settings === 'string') {
        settings = JSON.parse(historyItem.settings);
      }

      settings.forEach((approval: ConsentStorageDataApproval) => {
        const item: CookieConsentItem | undefined = consentsData.items.find(
          (item: CookieConsentItem) => item.id === approval.id,
        );
        if (item) {
          item.history.push({
            approval: approval,
            date: date,
          });
        }
      });
    });

    return consentsData;
  }

  /**
   * Public API method: append history to consent items
   */
  updateHistory(cookieConsentData: CookieConsent, history: CookieConsentHistory[]): CookieConsentHistory[] {
    const historyItem: CookieConsentHistory = {
      date: new Date(),
      settings: this.extractUserDecisions(cookieConsentData),
      url: window.location.href,
    };

    history.unshift(historyItem);
    history = history.slice(0, 3);

    return history;
  }

  private extractUserDecisions(data: CookieConsent): ConsentStorageDataApproval[] {
    return data.items.map((item: CookieConsentItem) => {
      return {
        id: item.id,
        value: item.accept,
      };
    });
  }
}
