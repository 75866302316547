import { TemplateResult, html, nothing } from 'lit-html';
import { Subject } from 'rxjs';

import { BaseTemplate } from 'app/core/base-template';
import { TemplateRef } from 'app/core/models/template.model';
import { AppLabels } from 'app/models/app-translations.model';
import { ConsentWrapperEvent } from './consent-wrapper.model';
import { ConsentButtonsTheme, ConsentWrapperStage } from 'app/models/consent-wrapper.model';
import { getButtonClassNameByUsingTheme } from 'app/helpers/get-button-class';

export class ConsentWrapperTemplate extends BaseTemplate implements TemplateRef {
  private goBack$!: Subject<Date>;
  private acceptAll$!: Subject<Date>;
  private saveConsents$!: Subject<Date>;
  private rejectAll$!: Subject<Date>;

  constructor() {
    super();
    this.registerEvents();
  }

  getTemplate(data: {
    elements: HTMLElement[];
    stage: ConsentWrapperStage;
    options: {
      showRejectAllBtn: boolean;
      buttonsTheme: ConsentButtonsTheme;
    };
  }): TemplateResult {
    switch (data.stage) {
      case ConsentWrapperStage.Details:
        return html`
          <div class="consent-modal__details-wrapper">
            ${data.elements.map((element) => html`${element}`)}
            ${this.getButtonsTemplate(data.options.showRejectAllBtn, data.options.buttonsTheme)}
          </div>
        `;
      case ConsentWrapperStage.General:
        return html`${data.elements.map((element) => html`${element}`)}`;
    }
  }

  private getButtonsTemplate(showRejectAllBtn: boolean, buttonsTheme: ConsentButtonsTheme): TemplateResult {
    const btnClass = getButtonClassNameByUsingTheme(buttonsTheme);

    return html`
      <div class="consent-modal__details-btn">
        <button class="c-btn" @click="${this.emitAcceptAll.bind(this)}">
          ${this.translate(AppLabels.AcceptAllBtnLabel)}
        </button>
        <button class="${btnClass}" @click="${this.emitSaveConsents.bind(this)}">
          ${this.translate(AppLabels.SaveConsentBtnLabel)}
        </button>

        ${showRejectAllBtn
          ? html`<button class="${btnClass}" @click="${this.emitSetAsRejectedAllConsents.bind(this)}">
              ${this.translate(AppLabels.RejectAllBtnLabel)}
            </button>`
          : nothing}

        <button
          class="${btnClass} consent-modal__details-btn-back
          ${buttonsTheme === ConsentButtonsTheme.Highlighted ? 'c-btn--icon-left' : ''}"
          @click="${this.emitGoBack.bind(this)}"
        >
          ${buttonsTheme === ConsentButtonsTheme.Highlighted
            ? html`<i class="cm-icon-arrow cm-icon-arrow--left"></i>`
            : nothing}
          ${this.translate(AppLabels.BackBtnLabel)}
        </button>
      </div>
    `;
  }

  private registerEvents(): void {
    this.registerEvent(ConsentWrapperEvent.AcceptAll, (this.acceptAll$ = new Subject()));
    this.registerEvent(ConsentWrapperEvent.SaveConsents, (this.saveConsents$ = new Subject()));
    this.registerEvent(ConsentWrapperEvent.GoBack, (this.goBack$ = new Subject()));
    this.registerEvent(ConsentWrapperEvent.RejectAll, (this.rejectAll$ = new Subject()));
  }

  private emitSaveConsents(): void {
    this.saveConsents$.next(new Date());
  }

  private emitSetAsRejectedAllConsents(): void {
    this.rejectAll$.next(new Date());
  }

  private emitAcceptAll(): void {
    this.acceptAll$.next(new Date());
  }

  private emitGoBack(): void {
    this.goBack$.next(new Date());
  }
}
