import { InternalMessages } from 'app/config/internal-messages';
import axios, { AxiosResponse } from 'axios';
import { ReplaySubject } from 'rxjs';
import { AppTranslationConfig, SupportedLanguages } from '../models/app-translations.model';
import { TranslateConfig, TranslationsMode } from '../models/translate.service.model';
import { AppTranslations } from '../translations/translations';
import { AppConfigService } from './app-config.service';

export class TranslateService {
  /**
   * Public observable property: emit true when translation service ready
   */
  ready$: ReplaySubject<boolean> = new ReplaySubject(1);

  private initialized = false;
  private config!: TranslateConfig;
  private translationsMode!: TranslationsMode;
  private currentLang: SupportedLanguages = SupportedLanguages.En;
  private static instance: TranslateService;
  private translations: object = {};
  private appConfigService: AppConfigService;

  static getInstance(): TranslateService {
    if (TranslateService.instance === undefined) {
      TranslateService.instance = new TranslateService();
    }

    return TranslateService.instance;
  }

  private constructor() {
    this.appConfigService = AppConfigService.getInstance();
  }

  /**
   * Public API method: init translate service
   */
  init(config: TranslateConfig): void {
    if (!this.initialized) {
      this.config = config;
      this.translationsMode = this.config.external ? TranslationsMode.External : TranslationsMode.Internal;
      this.currentLang = this.appConfigService.getInterfaceLang();

      this.initTranslations();
      this.initialized = true;
    }
  }

  /**
   * Public API method: translate string
   */
  translate(originalString: keyof object): string {
    switch (this.translationsMode) {
      case TranslationsMode.External:
        originalString = `COOKIE_INFO_${originalString}` as keyof object;
        return this.translations[originalString] ? this.translations[originalString] : originalString;

      case TranslationsMode.Internal:
        if (!this.translations[originalString]) {
          return originalString;
        }

        return this.translations[originalString][this.currentLang]
          ? this.translations[originalString][this.currentLang]
          : originalString;
    }
  }

  private initTranslations(): void {
    if (this.config.external === true) {
      this.loadTranslationFileFromRemote();
    } else {
      this.loadInternalTranslations();
    }
  }

  private loadInternalTranslations(): void {
    const translationData: AppTranslationConfig = AppTranslations;

    this.updateTranslations(translationData);
  }

  private loadTranslationFileFromRemote(): void {
    axios
      .request({
        method: 'GET',
        url: `${this.config.url}/${this.config.fileName}`,
      })
      .then((data: AxiosResponse<object>) => {
        this.updateTranslations(data.data);
      })
      .catch((error) => {
        console.error(InternalMessages.FetchTranslationError(error.message, error.request.responseURL));
        this.updateTranslations({});
      });
  }

  private updateTranslations(data: object | AppTranslationConfig): void {
    this.translations = data;
    this.ready$.next(true);
  }
}
