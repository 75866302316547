import { BehaviorSubject } from 'rxjs';

import {
  CookieConsent,
  ApiCookieConsent,
  CookieConsentCategory,
  CookieConsentItem,
  ApiCookieConsentCategory,
  ConsentStorageData,
  ConsentStorageDataApproval,
} from '../models/consent-data.model';
import { ConsentStorageService } from './consent-storage.service';
import { ChangeAgreementEventData } from '../components/consent-details/consent-details.model';
import { UserService } from './user.service';
import { ConsentRequestService } from './consent-request.service';
import { ConsentModalService } from './consent-modal.service';
import { ConsentHistoryService } from './consent-history.service';
import { DomPreparationService } from './dom-preparation.service';
import { ApiService } from './api.service';
import { AppEventService } from './app-event.service';
import { GtagConsentService } from './gtag-consent.service';
import { AppConfigService } from './app-config.service';

export class ConsentDataService {
  consentData$: BehaviorSubject<CookieConsent | null> = new BehaviorSubject<CookieConsent | null>(null);
  currentActiveItem$: BehaviorSubject<CookieConsentItem | null> = new BehaviorSubject<CookieConsentItem | null>(null);

  private static instance: ConsentDataService;

  private apiConsentData!: ApiCookieConsent;
  private normalizedConsentData!: CookieConsent;
  private consentData!: CookieConsent;
  private consentStorageService: ConsentStorageService;
  private userService: UserService;
  private consentRequestService: ConsentRequestService;
  private consentModalService: ConsentModalService;
  private consentHistoryService: ConsentHistoryService;
  private domPreparationService: DomPreparationService;
  private appEventService: AppEventService;
  private apiService: ApiService;
  private gtagConsentService: GtagConsentService;
  private appConfigService: AppConfigService;

  private constructor() {
    this.consentStorageService = ConsentStorageService.getInstance();
    this.userService = UserService.getInstance();
    this.consentRequestService = ConsentRequestService.getInstance();
    this.consentModalService = ConsentModalService.getInstance();
    this.consentHistoryService = ConsentHistoryService.getInstance();
    this.domPreparationService = DomPreparationService.getInstance();
    this.apiService = ApiService.getInstance();
    this.appEventService = AppEventService.getInstance();
    this.gtagConsentService = GtagConsentService.getInstance();
    this.appConfigService = AppConfigService.getInstance();
  }

  static getInstance(): ConsentDataService {
    if (ConsentDataService.instance === undefined) {
      ConsentDataService.instance = new ConsentDataService();
    }

    return ConsentDataService.instance;
  }

  /**
   * Public API method: set cookie consent data
   */
  setInitialCookieConsentData(data: ApiCookieConsent): void {
    this.apiConsentData = this.getFilteredConsentData(data);
    this.normalizedConsentData = this.getNormalizeConsentData(this.apiConsentData);
    this.consentData = this.mergeApiDataWithSavedInStorageData(this.normalizedConsentData);
    this.consentData = this.unifyConsentItemsRequiring(this.consentData);
    this.updateConsentData();
    this.updateCurrentConsentItemSubject(this.consentData?.items[0]?.id);
    this.prepareForGtagIntegrationIfEnabled();
    this.domPreparationService.updateDomStructure(this.consentData.items);
  }

  /**
   * Public API method: update current consent item
   */
  updateCurrentConsentItem(entityItemId: string): void {
    this.updateCurrentConsentItemSubject(entityItemId);
  }

  /**
   * Public API method: update cookie consent state
   */
  updateConsentState(consent: ChangeAgreementEventData, saveInStorage = false): void {
    const temp: CookieConsentItem | undefined = this.consentData.items.find(
      (item: CookieConsentItem) => item.id === consent.id || item.tag === consent.id,
    );

    if (temp) {
      temp.accept = consent.value;
    }

    if (saveInStorage) {
      this.saveDataInStorages();
    }
  }

  /**
   * Public API method: accept all consents
   */
  acceptAllConsents(): void {
    this.consentData.items.forEach((item: CookieConsentItem) => {
      item.accept = true;
    });

    this.saveDataInStorages();
    this.consentModalService.hideModal();
  }

  /**
   * Public API method: save current consents
   */
  saveConsents(): void {
    this.saveDataInStorages();
    this.consentModalService.hideModal();
  }

  /**
   * Public API method: decline all consents without required
   */
  declineAllConsents(): void {
    this.consentData.items.forEach((item: CookieConsentItem) => {
      item.accept = Boolean(item.required);
    });

    this.saveDataInStorages();
    this.consentModalService.hideModal();
  }

  /**
   * Public API method: revert consents setup to storage data
   */
  revertConsentsToStorageData(): void {
    this.consentData = this.mergeApiDataWithSavedInStorageData(this.getNormalizeConsentData(this.apiConsentData));
    this.updateConsentData();
  }

  /** Public API method: check if local data is older than remote data  */
  isLocalDataOlderThanRemoteData(): boolean {
    return this.compareConsentForCheckingNewerRemote();
  }

  private getFilteredConsentData(data: Readonly<ApiCookieConsent>): ApiCookieConsent {
    const filteredData = JSON.parse(JSON.stringify(data));

    Object.keys(filteredData.item).forEach((key) => {
      const localName = filteredData.item[key].name;
      if (!localName) {
        delete filteredData.item[key];
      }
    });

    return filteredData;
  }

  private prepareForGtagIntegrationIfEnabled(): void {
    if (this.appConfigService.getIntegrationWithGTM()) {
      this.gtagConsentService.prepareForIntegration(this.consentData.items);
    }
  }

  private compareConsentForCheckingNewerRemote(): boolean {
    const localSavedData = this.getLocalSavedData();

    if (!localSavedData) {
      return true;
    }

    const localConsentsIds = localSavedData.approval.map((consent) => consent.id);
    const apiConsentsIds = this.normalizedConsentData.items.map((item) => item.id);

    return !this.areArraysEqual(localConsentsIds, apiConsentsIds);
  }

  private areArraysEqual(array1: string[], array2: string[]): boolean {
    if (array1.length === array2.length) {
      return array1.every((element) => array2.includes(element));
    }

    return false;
  }

  private updateGlobalReferenceForExternalScripts(): void {
    this.apiService.updateConsentItems(this.getConsentItems());
  }

  private getConsentItems(): CookieConsentItem[] {
    return this.consentData.items;
  }

  private saveDataInStorages(): void {
    const parsedDataForSave: ConsentStorageData = this.prepareDataForSaveSettings(this.consentData);

    this.consentStorageService.saveLocalData(parsedDataForSave);

    this.consentRequestService.sendConfigToApi(parsedDataForSave).subscribe({
      next: () => {
        this.domPreparationService.updateDomStructure(this.consentData.items);

        if (this.consentModalService.getRefreshRequiredState()) {
          location.reload();
        } else {
          this.refreshUserConsentStory();
        }
      },
    });
  }

  private refreshUserConsentStory(): void {
    const history = this.consentStorageService.getLocalSavedData()?.history;

    if (history) {
      this.consentData = this.consentHistoryService.appendHistoryToItems(history, this.consentData);
      this.updateConsentData();
    }
  }

  private mergeApiDataWithSavedInStorageData(data: CookieConsent): CookieConsent {
    const storageData: ConsentStorageData | null = this.getLocalSavedData();

    if (storageData === null) {
      return data;
    }

    if (storageData.user !== undefined) {
      this.userService.updateUserData(storageData.user);
    }

    if (storageData.history !== undefined) {
      data = this.consentHistoryService.appendHistoryToItems(storageData.history, data);
    }

    data.items.forEach((item: CookieConsentItem) => {
      const tempApproval: ConsentStorageDataApproval | undefined = storageData.approval.find(
        (approval: ConsentStorageDataApproval) => approval.id === item.id,
      );

      if (tempApproval) {
        item.accept = tempApproval.value;
      }
    });

    return data;
  }

  private fillCategoryDetailsToItems(data: ApiCookieConsent): CookieConsent {
    let categories: CookieConsentCategory[] = [];
    const items: CookieConsentItem[] = [];

    Object.keys(data.category).forEach((key) => {
      const temp: ApiCookieConsentCategory = data.category[key];

      categories.push({
        id: key,
        name: temp.name,
        description: temp.description,
        position: Number(temp.position),
      });
    });

    Object.keys(data.item).forEach((key) => {
      const tempItem: CookieConsentItem = data.item[key];
      const tempCategory = categories.find((cat: CookieConsentCategory) => cat.id === tempItem.category);

      if (tempCategory) {
        tempItem.categoryEntity = tempCategory;
        tempItem.history = tempItem.history !== undefined ? tempItem.history : [];

        if (!tempCategory.items) {
          tempCategory.items = [];
        }

        tempCategory.items.push(tempItem);
        items.push(tempItem);
      }
    });

    categories = this.sortArrayByProperty(categories);

    return {
      category: categories,
      items: items,
      private_policy: data.private_policy,
      details_description: data.details_description,
    };
  }

  private extractCategoriesFromItems(data: ApiCookieConsent): CookieConsent {
    const categories: CookieConsentCategory[] = [];
    const items: CookieConsentItem[] = [];
    let position = 0;

    Object.keys(data.item).forEach((key) => {
      const tempItem: CookieConsentItem = data.item[key];
      const tempItemCategory = tempItem.category as unknown as CookieConsentCategory;

      tempItem.history = tempItem.history !== undefined ? tempItem.history : [];
      tempItem.required = Boolean(tempItem.required);

      if (!categories.some((cat) => cat.id === tempItemCategory.id)) {
        const tempCategory = {
          ...tempItemCategory,
          position: position,
        };

        categories.push(tempCategory);
        position = position + 1;
      }

      const preparedCategory = categories.find((cat) => cat.id === tempItemCategory.id);

      if (preparedCategory) {
        tempItem.categoryEntity = preparedCategory;

        if (!preparedCategory.items) {
          preparedCategory.items = [];
        }

        preparedCategory.items.push(tempItem);
      }

      items.push(tempItem);
    });

    return {
      category: categories,
      items: items,
      private_policy: data.private_policy,
      details_description: data.details_description,
    };
  }

  private getNormalizeConsentData(data: ApiCookieConsent): CookieConsent {
    if (data.category !== undefined) {
      return this.fillCategoryDetailsToItems(data);
    } else {
      return this.extractCategoriesFromItems(data);
    }
  }

  private unifyConsentItemsRequiring(data: CookieConsent): CookieConsent {
    data.items.forEach((item) => {
      item.required = Boolean(item.required);
    });

    return data;
  }

  private sortArrayByProperty(array: CookieConsentCategory[]) {
    return array.sort((a: CookieConsentCategory, b: CookieConsentCategory) => a.position - b.position);
  }

  private updateConsentData(): void {
    this.consentData$.next(this.consentData);
    this.updateGlobalReferenceForExternalScripts();
    this.appEventService.emitConsentChangeEvent();
  }

  private updateCurrentConsentItemSubject(entityItemId: string): void {
    const currentItem: CookieConsentItem | undefined = this.consentData.items.find(
      (item: CookieConsentItem) => item.id === entityItemId,
    );

    if (currentItem) {
      this.currentActiveItem$.next(currentItem);
    }
  }

  private getLocalSavedData(): ConsentStorageData | null {
    return this.consentStorageService.getLocalSavedData();
  }

  private prepareDataForSaveSettings(data: CookieConsent): ConsentStorageData {
    const currentHistory = this.consentStorageService.getLocalSavedData()?.history || [];
    const history = this.consentHistoryService.updateHistory(this.consentData, currentHistory);

    const storageData: ConsentStorageData = {
      approval: [],
      user: this.userService.getUser(),
      history: history,
    };

    data.items.forEach((item: CookieConsentItem) => {
      storageData.approval.push({
        id: item.id,
        value: item.accept ? item.accept : false,
      });
    });

    return storageData;
  }
}
