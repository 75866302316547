import { BehaviorSubject, filter } from 'rxjs';
import { ConsentDataService } from './consent-data.service';
import { CookieConsentItem } from '../models/consent-data.model';

export class ConsentMenuService {
  activeMenu$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  private static instance: ConsentMenuService;
  private consentDataService: ConsentDataService;

  private constructor() {
    this.consentDataService = ConsentDataService.getInstance();
    this.initData();
  }

  static getInstance(): ConsentMenuService {
    if (ConsentMenuService.instance === undefined) {
      ConsentMenuService.instance = new ConsentMenuService();
    }

    return ConsentMenuService.instance;
  }

  /**
   * Public API method: set active item on menu
   */
  setActiveMenu(itemEntityId: string): void {
    this.consentDataService.updateCurrentConsentItem(itemEntityId);
  }

  private initData(): void {
    this.consentDataService.currentActiveItem$.pipe(filter(Boolean)).subscribe((item: CookieConsentItem) => {
      this.activeMenu$.next(item.id);
    });
  }
}
